body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* font-size: 17pt; */

    --orange: #e08132;
    --blue: #3c4f73;
    --white: #fff;

    /* Text color */
    .font-orange {
        color: var(--orange);
    }

    .font-blue {
        color: var(--blue);
    }

    .font-white {
        color: var(--white);
    }

    /* Text size */
    .font-big {
        font-size: 32pt;
    }

    .font-header {
        font-size: 24pt;
    }

    .font-subheader {
        font-size: 18pt;
    }

    /* Text decoration */
    .bold {
        font-weight: bold;
    }

    /* Background color */
    .background-orange {
        background-color: var(--orange);
    }

    .background-blue {
        background-color: var(--blue);
    }

    .background-white {
        background-color: var(--white);
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
