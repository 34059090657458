.home-services {
    width: 80%;
    margin: 20px auto;
    padding: 20px;
    background-image: url('/public/images/background-dots.png');
    /* background-size: cover; */
    background-position: left bottom;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    text-align: center;
    border: 10px solid #e08132;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    padding-bottom: 40px;
}

.home-services h2 {
    text-align: center;
    color: #fff;
    color: #e08132;
    /* font-weight: normal; */
    font-size: 24pt;
    margin-bottom: 40px;
}

.home-services .card-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 10px;
}
