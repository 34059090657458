.two-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    width: 100%;
    order: initial;
}

@media (max-width: 768px) {
    .two-columns-regular {
        flex-direction: column;
    }
    
    .two-columns-reverse {
        flex-direction: column-reverse;
    }
}
