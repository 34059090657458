.app {
    margin: 0 25px;
    text-align: left;
    color: #3c4f73;

    h1 {
        text-align: center;
        font-size: 2em;
    }

    h2 {
        text-align: center;
        font-size: 1.5em;
    }

    h3 {
        text-align: center;
    }

    h4 {
        font-size: 18pt;
    }

    h5 {
        margin: 10px 0;
        font-size: 14pt;
    }

    h6 {
        text-align: center;
        margin: 20px 0;
        font-size: 1.6rem;
        margin-top: 5px;
        color: #e08132;
        font-weight: bold;
    }

    p {
        line-height: 1.6;
        font-size: 17pt;
    }

    ul {
        list-style-position: outside;
        padding-left: 30px;
    }

    li {
        line-height: 1.6;
        text-indent: 0;
        font-size: 17pt;
    }
}

@media (max-width: 768px) {
    .app {
        flex-basis: 100%;
        padding: 0 10px;
    }
}