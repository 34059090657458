.carousel-container {
    overflow: hidden;
    /* display: flex; */
    position: relative;
    align-items: center;
    justify-content: center;

    .prev {
        left: 10px;
    }
    
    .next {
        right: 10px;
    }
}

.carousel-slides {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-content {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
}

.carousel-text, .carousel-image {
    width: 50%;
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-text {
    flex: 1;
    text-align: center;
}

.carousel-text-wrapper {
    width: 80%;
    font-size: 16pt;

    button {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 18pt;
    }
}

.carousel-image {
    height: auto;
    /* display: block; */
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    z-index: 10;
    width: 30px;
    height: 100px;
}

.carousel-button:hover {
    background-color: #0056b3;
}








.carousel-image-container {
    flex: 1;
    /* aspect-ratio: 3 / 2; */
    /* width: 100%; */
    /* max-height: 400px; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #ccc; */
}

.carousel-image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
}


@media (max-width: 768px) {
    .carousel-container {
        height: 0px;
        margin: 10px;
    }
}