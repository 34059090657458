.contact-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    font-size: 15pt;
    span {
        font-size: 12pt;
    }

    .filler {
        height: 750px;
    }
}

.contact-info {
    width: 40%;
}

.contact-email {
    display: flex;
    align-items: center;
}

.contact-email img {
    margin-right: 10px;
}

.contact-form {
    width: 50%;
}

.contact-form form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.contact-form input,
.contact-form select,
.contact-form textarea,
.contact-form text {
    flex: 1 1 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12pt;
}

.contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18pt;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-email img {
    width: 24px;
    height: 24px;
}














.contact-form form {
    display: grid;
    grid-template-columns: 1fr; /* Single column setup by default */
    gap: 20px; /* Spacing between grid items */
}

.contact-form input,
.contact-form select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form textarea,
.contact-form button {
    width: 100%; /* Ensure these elements take full width */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@media (min-width: 768px) { /* Adjust this breakpoint to your needs */
    .contact-form form {
        grid-template-columns: 1fr 1fr; /* Two columns on larger screens */
    }

    .contact-form textarea,
    .contact-form button {
        grid-column: 1 / -1; /* Span across all columns */
    }
}













.contact-form form {
    display: grid;
    grid-template-columns: 1fr; /* Single column setup by default */
    gap: 20px; /* Spacing between grid items */
}

.contact-form input,
.contact-form select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form textarea {
    width: 100%; /* Ensure textarea takes full width */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form button {
    width: 150px; /* Set fixed width */
    height: 50px; /* Set fixed height */
    padding: 0; /* Remove any padding if previously set */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    justify-self: center; /* Center the button within its grid area */
}

@media (min-width: 768px) { /* Adjust this breakpoint to your needs */
    .contact-form form {
        grid-template-columns: 1fr 1fr; /* Two columns on larger screens */
    }

    .contact-form textarea,
    .contact-form button {
        grid-column: 1 / -1; /* Span across all columns */
    }
}
