.icon {
    flex: 0 0 100px;
    display: flex;
    align-items: top;
    justify-content: center;
    margin: 10px;

    .icon-border {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid black;
        overflow: hidden;
        background: radial-gradient(circle, #81D4FA 0%, #0277BD 100%);
        padding: 10px;
        margin: 10px;

        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            object-fit: cover;
        }
    }
}