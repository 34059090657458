.link-button {
    /* padding: 10px 20px; */
    /* margin: 5px; */
    /* background-color: #3c4f73; */
    text-align: center;
    /* border-radius: 5px; */
    /* text-decoration: none; */
    transition: filter 0.3s, transform 0.2s;
    /* font-weight: bold; */
    /* border: none; */
    cursor: pointer;
    transform-origin: center center;  /* Ensures scaling happens uniformly */
}

.link-button:hover, .link-button:focus {
    /* background-color: #0056b3; */
    transform: scale(1.05);
    /* text-decoration: none; */
    filter: brightness(120%);
}

.link-button:active {
    transform: scale(0.95);
}
