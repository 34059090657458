.image {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 20px;

    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
        object-fit: contain;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }

    .caption {
        font-size: 14pt;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .image {
        width: 80%;
        margin: 10px auto;
    }
}