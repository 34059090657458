.home-service-card {
    width: 200px;
    position: relative;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #164870; */
    margin: 1px;
}

.home-service-card .image-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: radial-gradient(circle, #6681ea 0%, #0c194d 100%); */
    /* border: 3px solid white; */
    /* border-radius: 13%; */
    /* margin-bottom: 10px; */
    transition: transform 0.3s ease;
}

.home-service-card .title-wrapper {
    height: 100px;
}

.home-service-card:hover .image-wrapper {
    transform: scale(1.1);
}

.home-service-card img {
    width: 90%;
    height: 90%;
}

.home-service-card p {
    font-size: 18pt;
    color: #fff;
}

.home-service-card .hover {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 200px;
    background-color: #164870;
    border: 1px solid #ccc;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    padding: 10px;
    z-index: 10;
    bottom: -150px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    border-radius: 10%;
    color: #fff;
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition for visibility and opacity */
}

.home-service-card:hover .hover {
    visibility: visible; /* Make it visible on hover */
    opacity: 1; /* Fade in on hover */
}
