.home .story {
    width: 100%;
    /* margin-top: 20px; */
    padding: 40px 0;
    background-image: url('/public/images/background-dots.png');
    background-size: cover;
    background-color: #164870;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    /* border-radius: 8px; */
}
  
.home .story h2.story-text {
    width: 60%;
    font-weight: normal;
    /* font-weight: bold; */
    text-align: center;
    margin: 0 auto;
    /* color: #f67f06; */
    font-size: 32pt;
    color: #fff;
}

.home .story h3.story-text{
    width: 60%;
    font-weight: normal;
    /* font-weight: bold; */
    text-align: center;
    margin: auto;
    /* color: #f67f06; */
    font-size: 24pt;
    color: #fff;
}

.home .story p.story-text {
    /* width: 60%; */
    margin: 0 auto;
    /* padding: 10px; */
    margin: 10px 100px;
    text-align: left;
    font-size: 16pt;
    color: #fff;
}

@media (max-width: 768px) {
    .home .story p.story-text {
        flex-basis: 100%;
        /* padding: 0 10px; */
        margin: 10px 10px;
        text-align: center;
    }
}
