.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #3c4f73;
    margin: 0;
    padding: 20px 0;

    font-size: 12pt;
    
    p {
        color: #fff;
        margin: 5px;
    }

    a {
        color: #00bfff;
        text-decoration: none;
        margin: 5px;
    }
    
    a:hover {
        text-decoration: underline;
        color: #fff;
    }
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;

        .hide {
            display: none;
        }
    }
}
