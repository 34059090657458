.headshot {
    flex: 0 0 200px;
    display: flex;
    align-items: top;
    justify-content: center;
    margin: 20px;

    img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0 4px 8px rgba(0,0,0,0.5);
    }
}

@media (max-width: 768px) {
    .headshot {
        img {
            width: 40vw;
            height: 40vw;
        }
    }
}